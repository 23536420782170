import React from "react"
import { SiteMetadata, WhatsApp } from "../components"
import { Link } from "gatsby"

import "typeface-roboto"

export default () => {
  return (
    <div className="flex min-h-screen items-center">
      <SiteMetadata title="Error 404" description="Page not found" />
      <WhatsApp />
      <div className="container">
        <h1 className="text-3xl lg:text-5xl font-extrabold leading-tight">
          Error 404
        </h1>
        <h3 className="text-lg lg:text-xl font-medium">
          Página no encontrada
          <br />
          <br />
          <Link to="/" className="text-blue-600">
            Ir a la página principal
          </Link>
        </h3>
      </div>
    </div>
  )
}
